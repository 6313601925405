<template>
  <div>
    <banner />
    <div>
      <v-card-text>
        <direct-access />
        <br />
        <v-card
          class="stretch pa-2"
          flat
          v-if="validaPermiso('Inicio Dashboard')"
        >
          <v-row>
            <v-col cols="6">
              <v-select
                :items="grupoOptions"
                v-model="grupoSeleted"
                item-text="display"
                item-value="value"
                append-icon="keyboard_arrow_down"
                label="Grupo"
                hint="Grupo"
                persistent-hint
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                :items="dashboardOptions.filter((c) => c.grupo == grupoSeleted)"
                v-model="dashboard"
                item-text="display"
                item-value="value"
                append-icon="keyboard_arrow_down"
                label="Gráficas"
                hint="Gráficas"
                persistent-hint
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <div v-if="dashboard == 'comisionCanales'">
            <comision-canales :tipoProductoOptions="tipoProductoOptions" />
          </div>

          <div v-if="dashboard == 'comisionSegmentos'">
            <comision-segmentos :tipoProductoOptions="tipoProductoOptions" />
          </div>

          <div v-if="dashboard == 'comisionEmpleados'">
            <comision-empleados :tipoProductoOptions="tipoProductoOptions" />
          </div>
          <div v-if="dashboard == 'comisionGruposComision'">
            <comision-grupos-comision :tipoProductoOptions="tipoProductoOptions" :grupoSeleted="grupoSeleted" />
          </div>
          <div v-if="dashboard == 'comisionAsociados'">
            <comision-asociados :tipoProductoOptions="tipoProductoOptions" />
          </div>
          <div v-if="dashboard == 'comision neta'">
            <comision-neta :tipoProductoOptions="tipoProductoOptions" />
          </div>
          <div v-if="dashboard == 'comision generada'">
            <comision-generada :tipoProductoOptions="tipoProductoOptions" />
          </div>
          <div v-if="dashboard == 'liquidez'">
            <liquidez />
          </div>
          <div v-if="dashboard == 'reclamacion'">
            <reclamacion />
          </div>
          <div v-if="dashboard == 'canal interno'">
            <canal-interno />
          </div>
        </v-card>
      </v-card-text>
    </div>
  </div>
</template>

<script>
// import apiHome from "@/api/home";
import apiProducto from "@/api/productos";
import { mapActions } from "vuex";

export default {
  components: {
    banner: () => import("@/components/home/banner"),
    directAccess: () => import("@/components/home/direct_access"),
    ComisionNeta: () =>
      import("@/components/dashboards/comision_neta_por_linea"),
    ComisionCanales: () =>
      import("@/components/dashboards/comision_por_canales"),
      ComisionSegmentos: () =>
      import("@/components/dashboards/comision_por_segmentos"),
    ComisionEmpleados: () =>
      import("@/components/dashboards/comision_por_empleados"),
    ComisionGruposComision: () =>
      import("@/components/dashboards/comision_por_grupos_comision"),
      ComisionAsociados: () =>
      import("@/components/dashboards/comision_por_asociados"),
    ComisionGenerada: () =>
      import("@/components/dashboards/comisiones_generadas"),
    Liquidez: () => import("@/components/dashboards/liquidez"),
    Reclamacion: () => import("@/components/dashboards/reclamacion"),
    CanalInterno: () =>
      import("@/components/dashboards/comicion_canal_interno.vue"),
  },
  data() {
    return {
      tipoProductoOptions: [],
      grupoOptions: [
        { value: 1, display: "Externo" },
        { value: 2, display: "Interno" },
      ],
      dashboardOptions: [
        
        {
          grupo: 1,
          value: "comision generada",
          display: "Evolución de las comisiones",
        },
        
        { grupo: 1, value: "reclamacion", display: "Reclamaciones" },
        {
          grupo: 2,
          value: "canal interno",
          display: "Comisión canales internos",
        },
        { grupo: 1, value: "comisionCanales", display: "Comisión por Canales" },
        { grupo: 1, value: "comisionSegmentos", display: "Comisión por Segmentos" },
        { grupo: 2, value: "comisionEmpleados", display: "Comisión por Empleados" },
        { grupo: 1, value: "comisionGruposComision", display: "Comisión grupos de comisón" },
        { grupo: 2, value: "comisionGruposComision", display: "Comisión grupos de comisón" },
        { grupo: 1, value: "comisionAsociados", display: "Comisión por Asociados" },
      ],
      grupoSeleted: 1,
      dashboard: "comision neta",
      breadcrumb: [
        {
          text: "Inicio",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    validaPermiso(accion) {
      return (
        this.currentUser.accesos.filter(
          (c) => c.title === accion && c.type === 6
        ).length > 0
      );
    },
    getTipoProducto() {
      apiProducto.getFamiliaProducto().then((response) => {
        this.tipoProductoOptions = response.data;
      });
    },
    validarPermisosDashboard(){

      if(this.validaPermiso('Dashboard Liquidez del Canal')){

        this.dashboardOptions.splice(0,0,{ grupo: 1, value: "liquidez", display: "Liquidez del canal" })
      }

      if(this.validaPermiso('Dashboard Comision Neta')){

        this.dashboardOptions.splice(0,0,{
            grupo: 1,
            value: "comision neta",
            display: "Comisión neta por linea",
          })
      }

    }
  },
  watch: {
    canalSelected: function () {
      this.getEmpleados();
    },
    periodoOptions: function () {
      if (this.periodoOptions.length > 0) {
        this.periodoSelected = this.periodoOptions[0].FechaFin;
      }
    },
    canalOptions: function () {
      if (this.canalOptions.length > 0) {
        this.canalSelected =
          this.$store.getters.user.canalid == null
            ? this.canalOptions[0].CanalId
            : this.$store.getters.user.canalid;
      }
    },
  },
  mounted() {
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.getTipoProducto();
    this.validarPermisosDashboard();
  },
};
</script>
<style scoped>
button.v-btn.v-btn--icon.theme--dark {
  background: rgba(0, 0, 0, 0.5);
}
button.v-btn.v-btn--icon.v-btn--.theme--dark.v-carousel__controls__item {
  background: transparent;
}
.routerlink-div {
  background: rgb(245, 245, 245);
}
.routerlink {
  text-decoration: none !important;
}
.shortcut {
  /* height: 100%; */
  margin: 2px;
  padding: 5px;
  min-height: 195px;
  border: solid;
  border-color: rgb(245, 245, 245);
  border-width: thin;
}
/* .v-tab {
  justify-content: left !important;
  text-transform: none;
} */
</style>